import {createRouter, createWebHashHistory} from 'vue-router'
export default (app,routes) => {
    const router = createRouter({
        history: createWebHashHistory(),
        routes: [
            {
                path: '/',
                redirect: '/SchedulingView'
            },
            ...routes
        ]
    })
    router.beforeEach((to) => {

    });
    app.use(router)
}