import {createStore} from 'vuex'

const store = {
  modules: {

  },
}
export default (app) => {
  store.getters = {
    $api() {
      return app.config.globalProperties.$api;
    }
  }
  app.use(createStore(store))
}