let dateFormat = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
Object.defineProperty(Date.prototype, '$format', {
    value: dateFormat,
    configurable: false,
    enumerable: false,
    writable: false
});

let toCnFixed = function (digits) {
    if (typeof (this) !== 'number')
        return this;
    if (typeof (digits) !== 'number')
        digits = 2;
    if (this >= 100000000)
        return `${(this / 100000000).toFixed(digits)}亿`;
    if (this >= 10000)
        return `${(this / 10000).toFixed(digits)}万`;
    return this.toFixed(digits);
};

Object.defineProperty(Number.prototype, 'toCnFixed', {
    value: toCnFixed,
    configurable: false,
    enumerable: false,
    writable: false
});