import init_scheduling_view from './scheduling/SchedulingView.js'

const scheduling_view_route = []
const page_api_path = {}
init_scheduling_view(scheduling_view_route, page_api_path)

export default (routes,api_paths)=>{
    routes.push(...scheduling_view_route)
    Object.assign(api_paths,page_api_path)
}
