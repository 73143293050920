import axios from 'axios'

let setRequestOption = function (option,api_config) {
    let requestOption = {
        method: option.method ? option.method : 'post',
        url: option.url,
    };
    requestOption.headers = api_config.headers();
    if (option.headers)
        requestOption.headers = Object.assign(requestOption.headers, option.headers);
    if (option.method === 'json') {
        requestOption.headers['Content-Type'] = "application/json;charset=UTF-8"
        requestOption.method = 'post'
    }
    if (option.param) {
        if (requestOption.method === 'post')
            requestOption.data = option.param;
        else
            requestOption.params = option.param;
    }
    if (option.responseType)
        requestOption.responseType = option.responseType;
    return requestOption;
}

let asyncHttpRequest = function (api_config) {
    let requestOption = setRequestOption(this,api_config);
    return axios(requestOption)
        .then((d) => {
            this.success.call(this.vue, d.data);
        })
        .catch((e) => {
            if (this.error) {
                this.error.call(this.vue, e)
            } else {
                this.eventbus.emit('message', '服务器错误');
            }
        })

};

function getSubPathConfig(pathKey,api_config) {
    let subPathConfig = api_config.paths;
    let pathsArray = pathKey.split('.');
    for (let subPath of pathsArray) {
        if (!subPathConfig[subPath])
            return null;
        subPathConfig = subPathConfig[subPath];
    }
    return subPathConfig;
}

function Api(e, api_config) {
    let event = e;

    return function (option) {
        if (option.key) {
            let path = null;
            if (option.key.indexOf('.') > 0)
                path = getSubPathConfig(option.key,api_config);
            else
                path = api_config.paths[option.key];
            if (!path) {
                event.emit('message', '访问的资源不存在!');
                return;
            }
            option.url = api_config.domain;
            if (path.path)
                option.url += path.path;
            if (path.headers)
                option.headers = Object.assign(option.headers ? option.headers : {}, path.headers);
            option.method = path.method;
        } else if (option.url) {
            if (!option.method)
                option.method = 'get'
        } else {
            console.log('require url');
            return;
        }
        option.vue = this;
        option.eventbus = event;
        return asyncHttpRequest.call(option,api_config)

    }
}

export const api = Api

export default (app, api_config) => {
    app.config.globalProperties.$api = new Api(app.config.globalProperties.$eventBus, api_config)
}

