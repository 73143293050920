const route = {
    path: '/SchedulingView',
    name: 'SchedulingView',
    component: () => import('./SchedulingView.vue'),
}
const page_api_path = {
        'scheduling_view': {
        'schedule_list': {method: 'post', path: '/midi_union/zibo_scheduling/scheduling-list'},
        'get-live-room': {method: 'post', path: '/midi_union/zibo_scheduling/get-live-room'},
    },
}

export default function (childrenRoutes, api_paths) {
    childrenRoutes.push(route);
    Object.assign(api_paths, page_api_path)
}
