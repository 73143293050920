import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import initViews from './views'
import installVueRouter from './router.js'
import installApi from './plugins/api.js'
import api_config from './api.config'
import './plugins/extend.js'
import installStore from './store'
import installEventBus from './plugins/event-bus'
import './assets/css/index.scss';

const app=createApp(App)
const routes=[];
const api_paths=api_config.paths
initViews(routes,api_paths)
installVueRouter(app,routes);
installEventBus(app)
installApi(app,api_config)
installStore(app)
app.mount('#app')
// createApp(App).use(store).use(router).mount('#app')
