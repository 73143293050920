const apiHostDev = 'http://motiadmin.modubus.com/mga_api/api';
const apiHostPro = 'http://motiadmin.modubus.com/mga_api/api';
const apiHostTest = 'https://slb.mediabook.com.cn/mga_api_test/public/index.php/api';
let apiHost = '';
switch (process.env.NODE_ENV) {
    case 'development':
        apiHost = apiHostPro;
        break;
    case 'test':
        apiHost = apiHostTest;
        break;
    default :
        apiHost = apiHostPro
}
export default {
    domain: apiHost,
    headers() {
        return {}
    },
    paths: {


    }
}
